import React, { lazy, Suspense } from "react";
import { NavContent } from "primary-navbar!sofe";
import { useCss } from "kremling";
import { HashRouter, Route, NavLink, Redirect } from "react-router-dom";
import { ErrorBoundary } from "error-logging!sofe";
import { CpLoader, CpIcon } from "canopy-styleguide!sofe";
import { useWithUserAndTenant, useHasAccess } from "cp-client-auth!sofe";
import Prototypes from "./prototypes/prototypes.component.js";
import Prototype from "./prototypes/prototype.component.js";

const Users = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-users' */ "./users/users.component.js"
    )
);

const Notifications = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-notifications' */ "./notifications/notifications.component.js"
    )
);

const Communications = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-communications' */ "./communications/communications.routes.js"
    )
);

const Transcripts = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-transcripts' */ "./transcripts/transcripts.component.js"
    )
);

const PublicApi = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-transcripts' */ "./public-api/public-api.component"
    )
);

const CanopyPayments = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-payment' */ "./canopy-payments/canopy-payments.component.js"
    )
);

const Analytics = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-analytics' */ "./analytics/analytics.component.js"
    )
);

const Integrations = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-integrations' */ "./integrations/integrations.component.js"
    )
);

const Betas = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-betas' */ "./betas/betas.component.js"
    )
);

const Tenants = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-tenants' */ "./tenants/tenants.component.js"
    )
);

const ForceUiRefresh = lazy(
  () =>
    import(
      /* webpackChunkName: 'canopy-admin-ui-force-ui-refresh' */ "./force-ui-refresh/force-ui-refresh.js"
    )
);

function RouteLoader({ children }) {
  return <Suspense fallback={<CpLoader size="lg" />}>{children}</Suspense>;
}

function Routes() {
  const scoped = useCss(css);
  const [loggedInUser] = useWithUserAndTenant();
  const isCanopyUser = useHasAccess("canopy_users");
  const isCanopyAdmin = useHasAccess("canopy_admin");
  const hasAdminAccess = useHasAccess([
    "canopy_admin",
    "canopy_users",
    "canopy_trials",
    "canopy_prototypes",
  ]);
  const isCanopyIntegrationsAdmin = useHasAccess("canopy_admin_integrations");
  const hasCanopyPrototypes = useHasAccess("canopy_prototypes");
  const hasCanopyForceUIRefresh = useHasAccess("canopy_admin_force_ui_refresh");
  const hasCanopyBetas = useHasAccess(["canopy_betas", "canopy_admin"]);
  if (!loggedInUser) return null;

  // DO NOT REMOVE 'fs-exclude' it has been added to
  // prevent fullstory picking up canopy-admin content
  return (
    <div className="fs-exclude">
      <HashRouter>
        {!hasAdminAccess && <Redirect to="/403" />}
        <HashRouter basename="/canopy-admin">
          <NavContent hasTopnavSecondary={false}>
            <div {...scoped} className="app-wrapper">
              <div className="nav-wrapper">
                <div className="section-container">
                  <NavLink to="/users" activeClassName="active">
                    <CpIcon name="person-people" />
                    <span className="cp-ml-16">Users</span>
                  </NavLink>
                  {isCanopyAdmin && (
                    <NavLink to="/tenants" activeClassName="active">
                      <CpIcon name="misc-buildings" />
                      <span className="cp-ml-16">Tenants</span>
                    </NavLink>
                  )}
                  {isCanopyUser && (
                    <>
                      <NavLink to="/notifications" activeClassName="active">
                        <CpIcon name="misc-bell" />
                        <span className="cp-ml-16">Notifications</span>
                      </NavLink>
                      <NavLink to="/analytics" activeClassName="active">
                        <CpIcon name="misc-bar-graph" />
                        <span className="cp-ml-16">Analytics</span>
                      </NavLink>
                      <NavLink
                        to="/communications/email-accounts"
                        activeClassName="active"
                      >
                        <CpIcon name="communication-envelope" />
                        <span className="cp-ml-16">Communications</span>
                      </NavLink>
                      <NavLink to="/transcripts" activeClassName="active">
                        <CpIcon name="file-statement" />
                        <span className="cp-ml-16">Transcripts</span>
                      </NavLink>
                      <NavLink to="/canopy-payments" activeClassName="active">
                        <CpIcon name="billing-circle-open" />
                        <span className="cp-ml-16">Canopy Payments</span>
                      </NavLink>
                      {isCanopyIntegrationsAdmin && (
                        <NavLink to="/integrations" activeClassName="active">
                          <CpIcon name="shape-circles-connected" />
                          <span className="cp-ml-16">Integrations</span>
                        </NavLink>
                      )}
                    </>
                  )}
                  {hasCanopyBetas && (
                    <NavLink to="/Betas" activeClassName="active">
                      <CpIcon name="misc-gift-box" />
                      <span className="cp-ml-16">Betas</span>
                    </NavLink>
                  )}
                  {hasCanopyPrototypes && (
                    <NavLink to={"/prototypes"} activeClassName="active">
                      <CpIcon name="misc-paintbrush" />
                      <span className="cp-ml-16">Prototypes</span>
                    </NavLink>
                  )}
                  {hasCanopyForceUIRefresh && (
                    <NavLink to="/force-ui-refresh" activeClassName="active">
                      <CpIcon name="af-refresh" />
                      <span className="cp-ml-16">Force UI Refresh</span>
                    </NavLink>
                  )}
                </div>
              </div>
              <div className="content-wrapper">
                <Route
                  path="/users"
                  render={() => (
                    <RouteLoader>
                      <Users />
                    </RouteLoader>
                  )}
                />
                {isCanopyAdmin && (
                  <Route
                    path="/tenants"
                    render={() => (
                      <RouteLoader>
                        <Tenants />
                      </RouteLoader>
                    )}
                  />
                )}
                {isCanopyUser && (
                  <>
                    <Route
                      path="/notifications"
                      render={() => (
                        <RouteLoader>
                          <Notifications />
                        </RouteLoader>
                      )}
                    />
                    <Route
                      path="/analytics"
                      render={({ match }) => (
                        <RouteLoader>
                          <Analytics match={match} />
                        </RouteLoader>
                      )}
                    />
                    <Route
                      path="/communications"
                      render={({ match }) => (
                        <RouteLoader>
                          <Communications match={match} />
                        </RouteLoader>
                      )}
                    />
                    <Route
                      path="/transcripts"
                      render={({ match }) => (
                        <RouteLoader>
                          <Transcripts match={match} />
                        </RouteLoader>
                      )}
                    />
                    <Route
                      path="/canopy-payments"
                      render={({ match }) => (
                        <RouteLoader>
                          <CanopyPayments match={match} />
                        </RouteLoader>
                      )}
                    />
                    {isCanopyIntegrationsAdmin && (
                      <Route
                        path="/integrations"
                        render={({ match }) => (
                          <RouteLoader>
                            <Integrations match={match} />
                          </RouteLoader>
                        )}
                      />
                    )}
                  </>
                )}
                <Route
                  path="/public-api"
                  render={() => (
                    <RouteLoader>
                      <PublicApi loggedInUser={loggedInUser} />
                    </RouteLoader>
                  )}
                />

                {hasCanopyBetas && (
                  <Route
                    path="/betas"
                    render={() => (
                      <RouteLoader>
                        <Betas />
                      </RouteLoader>
                    )}
                  />
                )}
                <Route
                  path="/force-ui-refresh"
                  render={() => (
                    <RouteLoader>
                      <ForceUiRefresh />
                    </RouteLoader>
                  )}
                />
                {hasCanopyPrototypes && (
                  <>
                    <Route
                      exact
                      path="/prototypes"
                      render={() => (
                        <RouteLoader>
                          <Prototypes />
                        </RouteLoader>
                      )}
                    />
                    <Route
                      exact
                      path="/prototypes/:prototypeId"
                      render={() => (
                        <RouteLoader>
                          <Prototype />
                        </RouteLoader>
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </NavContent>
        </HashRouter>
      </HashRouter>
    </div>
  );
}

const css = `
  & .app-wrapper {
    display: flex;
  }
  & .cps-card {
    overflow: auto;
  }
  & .content-wrapper {
    margin-left: 24rem;
    width: calc(100% - 24rem);
  }
  & .nav-wrapper {
    position: fixed;
    z-index: 97;
    overflow: hidden;
    height: 100%;
    width: 26.2rem;
  }
  & .section-container {
    width: calc(100% - 2.4rem * 2);
    border: 1px solid var(--cp-color-card-border);
    background-color: var(--cp-color-card-bg);
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 8px 0;
  }

  & .section-container > a {
    color: var(--cp-color-app-secondary-text);
    height: 40px;
    display: flex;
    align-items: center;
    padding: 14px;
  }

  .section-container > a:hover, .section-container > a:active {
    text-decoration: none;
  }

  .section-container > a:hover {
    background-color: var(--cp-color-menu-hover-bg);
  }

  .section-container > a.active {
    color: var(--cp-color-app-primary);
  }

  .section-container > a.active > .cps-icon {
    color: var(--cp-color-app-primary);
  }
`;

export default ErrorBoundary({ featureName: "canopy-admin-ui" })(Routes);

// Not used anywhere but keeping it in case we need it later
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PrivateRoute = ({ render, permitted, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      permitted ? (
        render(props)
      ) : (
        <HashRouter>
          <Redirect to="/403" />
        </HashRouter>
      )
    }
  />
);
